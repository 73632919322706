/* eslint-disable */

import {ColumnConfig, IColumnConfig} from './columnConfig';
import { ColumnTemplateType } from './enums';

export class DateColumnConfig extends ColumnConfig {
  mode?: string;

  isUtc?: boolean;

  constructor(config: IDateColumnConfig) {
    if (!config.editTemplateName) config.editTemplateName = ColumnTemplateType.DateTime;
    if (!config.defaultValue) config.defaultValue = null as Date | null;
    super(config);
    this.mode = config.mode;
    this.displayField = this.dataField;
    this.dataType = "date";
    this.isUtc = true;
  }
}

export interface IDateColumnConfig extends IColumnConfig {
  mode?: string;
  isUtc?: boolean;
}